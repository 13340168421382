import React from "react"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { FaCheckCircle as CheckIcon } from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./launch.scss"
import FormsService from "../service/formsservice"

export default class Launch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      formData: {},
    }
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState((oldState) => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    this.setState({ submitting: true })
    let data = JSON.stringify(this.state.formData)
    FormsService.launchForm(data)
      .then((res) => {
        if (res) {
          this.setState({ submitted: true })
        }
      })
      .catch((error) => console.log("Error: ", error))
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  render() {
    const { data } = this.props
    const { submitted, submitting } = this.state

    return (
      <Layout>
        <SEO title="Launch" />
        <div className="outer-wrapperLaunch">
          <Container className="containerLaunch">
            <div className="inner-wrapperLaunch">
              <div className="desc-parent">
                <h1>Launch Form</h1>
                <p className="desc-parent-detail">
                  Ready to Launch SHAPE?
                  <br />
                  <br /> Launch SHAPE in any part of your business in two simple
                  steps.
                  <br />
                  <br />
                  Simply complete this form and we’ll be in touch shortly to set
                  you up.
                </p>
              </div>
              <form
                className="form-parent"
                name="launch"
                action="/thanks/"
                onSubmit={this.handleSubmit}
                disabled={submitted}
                id="launch-form"
              >
                <Row>
                  <Col sm={12}>
                    <div className="input-parent">
                      <label>Your name *</label>
                      <input
                        type="text"
                        id="launch-input-name"
                        required
                        name="name"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="input-parent">
                      <label>Your email address *</label>
                      <input
                        type="text"
                        required
                        id="launch-input-email"
                        name="email"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="input-parent">
                      <label>Company name *</label>
                      <input
                        type="text"
                        required
                        id="launch-input-companyname"
                        name="companyName"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        Your office address *
                      </label>
                      <input
                        type="text"
                        required
                        id="launch-input-officeaddress"
                        name="address"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="input-parent">
                      <label>Country/Countries to deploy to *</label>
                      <input
                        type="text"
                        required
                        id="launch-input-countries"
                        name="countries"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        Name of signatory
                        <br />
                        <span>
                          This is the person who will be signing all contractual
                          documents.
                        </span>
                      </label>
                      <div>
                        <input
                          type="text"
                          id="launch-input-signatory"
                          name="signatoryName"
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        Name of nominated SSA *
                        <br />
                        <span>
                          The SSA, or SHAPE Senior Administrator, is the SHAPE
                          Champion in your organisation. This person will work
                          with us to set up SHAPE for your company and
                          coordinate with us. This may be you, or a colleague.
                        </span>
                      </label>
                      <div>
                        <input
                          type="text"
                          required
                          id="launch-input-ssaname"
                          name="ssaName"
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        Email of nominated SSA *
                      </label>
                      <input
                        type="text"
                        required
                        id="launch-input-ssaemail"
                        name="ssaEmail"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        Your PO number
                        <br />
                        <span>PO number is your purchase order number.</span>
                      </label>
                      <input
                        type="text"
                        id="launch-input-po-number"
                        name="purchaseOrderNumber"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        Eligible employees in your organisation
                        <br />
                        <span>
                          This is the number of employees who will participate
                          in the SHAPE Survey.
                        </span>
                      </label>
                      <input
                        type="text"
                        id="launch-input-eligibleemployees"
                        name="numberOfEmployees"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        Will your employees be comfortable completing the survey
                        in English?
                        <br />
                        <span>
                          The service is currently available in English. For
                          other languages,{" "}
                          <a href="/contact" target="_blank">
                            contact us
                          </a>
                          .
                        </span>
                      </label>
                      <div>
                        <label className="form-group">
                          <input
                            type="radio"
                            value="Yes"
                            id="launch-input-englishsurvey-yes"
                            className="radio-input"
                            name="comfortableEnglishSurvey"
                            onChange={this.handleChange}
                          />{" "}
                          Yes
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            type="radio"
                            className="radio-input"
                            id="launch-input-englishsurvey-no"
                            value="No"
                            name="comfortableEnglishSurvey"
                            onChange={this.handleChange}
                          />{" "}
                          No
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            type="radio"
                            id="launch-input-englishsurvey-maybe"
                            value="Maybe"
                            className="radio-input"
                            name="comfortableEnglishSurvey"
                            onChange={this.handleChange}
                          />{" "}
                          Maybe
                        </label>
                      </div>
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        Are you working with a SHAPE Partner, or have you been
                        referred by another company/person?
                        <br />
                        <span>
                          Knowing who you're working with lets us coordinate the
                          best experience for you.
                        </span>
                      </label>
                      <input
                        type="text"
                        id="launch-input-distributionpartner"
                        name="refferedBy"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="ctaLaunch">
                  {submitted && (
                    <div className="submittedSuccess">
                      Thank you. We’ll be in touch very shortly.
                    </div>
                  )}
                  <button
                    type="submit"
                    id="launch-form-submit-button"
                    disabled={submitted || submitting}
                    className="text-white"
                  >
                    {(submitted && "Submitted") ||
                      (submitting && "Submitting...") ||
                      "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allCountryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allPositionJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allIndustryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allHowFindJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allCompanySizeJson {
      edges {
        node {
          value
          name
        }
      }
    }
  }
`
